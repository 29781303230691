import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { styled } from '@mui/system';
import theme from '../theme';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import OpenSign from '../img/icons/OpenBoard-BP.svg';
import Fees from '../img/icons/Bill.svg';
import ShoutOut from '../img/icons/Trainee.svg';

const ContactBox = styled(Paper)({
	padding: theme.spacing(4),
	borderRadius: theme.spacing(2),
	zIndex: 1,
	position: 'relative',
});

const ContactDets = (props) => {
	const {
		top,
		margin
	} = props;

	const data = useStaticQuery(graphql`
		query CONTACT_INFO {
			detailInfo: markdownRemark(
				frontmatter: { templateKey: { eq: "clinic-details" } }
			) {
				frontmatter {
					open_hours {
						day
						time
					}
				}
			}
			generalInfo: site {
				siteMetadata {
					phone1
				}
			}
		}
	`);

	const { phone1 } = data.generalInfo.siteMetadata;
	const { open_hours } = data.detailInfo.frontmatter;

	return (
		<ContactBox
			className='section'
			elevation={6}
			sx={{top: {top}, m: {margin}}}
		>
			<Box>
				<Grid container spacing={6}>
					<Grid item xs={12} sm={12} md={12} className='section'>
						<Typography variant='h3' className='title' align='center'>
							For all Appointments please call
						</Typography>
						<Typography
							// mt={2}
							variant='h1'
							sx={{ fontSize: '2.6rem' }}
							align='center'
							color='secondary'
						>
							{phone1}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								mt: 4,
							}}
						>
							<Avatar
								alt='Open Hours'
								src={OpenSign}
								sx={{
									width: 64,
									height: 64,
									display: 'inline-flex',

									// padding: '6px',
									// top: '10px',
								}}
							/>
							<Typography variant='h4' className='title' align='center' m={0}>
								Opening hours are:
							</Typography>
							{open_hours.map((open) => (
								<Typography>
									{open.day} {open.time}
								</Typography>
							))}
						</Box>
						<Stack direction='row' spacing={6} mt={4} justifyContent='center'>
							<Button
								sx={{ display: 'flex' }}
								variant='outlined'
								component={Link}
								to='/clinic-details'
								size='large'
								startIcon={
									<Avatar
										alt='Open Hours'
										src={Fees}
										sx={{
											width: 'auto',
											height: 50,
											display: 'inline-flex',
											p: '6px',
											ml: -1,
											// top: '10px',
										}}
									/>
								}
							>
								Fee Schedule
							</Button>
							<Button
								sx={{ display: 'flex' }}
								href='javascript:void(Tawk_API.toggle())'
								size='large'
								variant='outlined'
								startIcon={
									<Avatar
										alt='Open Hours'
										src={ShoutOut}
										sx={{
											width: 'auto',
											height: 50,
											display: 'inline-flex',
											p: '6px',
											ml: -1,
											// top: '10px',
										}}
									/>
								}
							>
								Get in Touch
							</Button>
						</Stack>
					</Grid>
					{/* <Features gridItems={intro.blurbs} gridWidth={6} /> */}
				</Grid>
			</Box>
		</ContactBox>
	);
};

export default ContactDets;
